import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useTheme, Theme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { RedirectLoginOptions } from '@auth0/auth0-react/dist/auth0-context'

import { languages } from '../utils/i18n'
import useOneTrust from '../hooks/useOneTrust'
import useTagManager from '../hooks/useTagManager'
import useHotjar from '../hooks/useHotjar'
import Routes from '../Routes'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Loader from '../components/Loader'
import { AppContainer } from './App.styles'

export const redirectOptions = (target: string, theme: Theme, langCodes: string[]) => {
  let result: RedirectLoginOptions = { appState: { target } }
  if ((theme.brand === 'verlinde' || theme.brand === 'swf') && theme.registerUri) {
    return {
      ...result,
      register_uri: `${theme.registerUri.storeDomain}/${theme.registerUri.storeAbbr}/${langCodes[0]}/register`,
      ui_locales: langCodes.join(' '),
    }
  }
  return result
}

const App: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const { portalName } = useTheme()
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'en'
    document.documentElement.lang = storedLanguage;
  }, []);
  useOneTrust()
  useTagManager()
  useHotjar()

  const headerText = portalName! || t('alpha_customer_portal')

  if (isAuthenticated) {
    return (
      <AppContainer>
        <Header headerText={headerText}></Header>
        <Routes />
        <Footer />

      </AppContainer>
    )
  }

  if (isLoading) {
    return (
      <AppContainer>
        <main style={{ paddingTop: '20px' }}>
          <Loader loadingText={t('loading_please_wait')}></Loader>
        </main>
      </AppContainer>
    )
  }

  // Automatic redirect.
  // NOTE: auth0-react takes care of converting known props to snake_case,
  // for anything custom we need to do it ourselves.
  // Safari might get into a redirect loop if auth is hosted on a different domain.
  // See https://auth0.com/docs/authorization/renew-tokens-when-using-safari#itp-and-browser-behavior
  const langs = [
    i18n.language,
    ...languages.filter((l) => l.langCode !== i18n.language).map((lang) => lang.langCode),
  ]
  loginWithRedirect(redirectOptions(window.location.href, theme, langs))

  // This text flashes before the redirect happens.
  // Could be factored out into its own component where it would display the text after a timeout.
  // TODO: look into translations
  return (
    <AppContainer>
      <main style={{ paddingLeft: '20px' }}>
        <h1>{headerText}</h1>
        <p>Redirecting you to the login page...</p>
        <button
          style={{ textDecoration: 'underline' }}
          onClick={() => loginWithRedirect(redirectOptions(window.location.href, theme, langs))}
        >
          Click here to log in manually
        </button>
      </main>
    </AppContainer>
  )
}

export default App
